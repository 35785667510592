import React, {useRef, useState} from "react";
import "../styles/home.css";

import axiosInstance from "../utils/axios";

const DefineNewUsername = ({setIsFirstConnection, user, setUser}) => {

    const [error, setError] = useState('');
    const [successLog, setSuccessLog] = useState("");

    const inputRandomRef = useRef(null);

    const idUser = localStorage.getItem("idUser");


    const updateUsername = async () => {
        try {
            const response = await axiosInstance.patch("/wp-json/keole_wytest/v1/username", {
                username: user, id_user: idUser,
            });
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.log("Erreur lors de la connexion :", error);
        }
    }

    const handleClickUpdateUsername = async (e) => {
        e.preventDefault();
        if (user) {
            const updateUser = await updateUsername();
            const {getUsername} = updateUser;
            if (updateUser.message === "Ce pseudonyme est déjà utilisé") {
                setError(updateUser.message);
            } else if (validateUsername(getUsername)) {
                localStorage.setItem("username", getUsername);
                setUser(getUsername);
                setSuccessLog("Pseudonyme enregistré")
                setTimeout(() => {
                    setIsFirstConnection(false);
                }, 2000)
            } else {
                inputRandomRef.current.value = "";
            }
        } else {
            setError('Il faut écrire votre pseudonyme');
        }
    }


    const validateUsername = (username) => {
        const isValid = /^[A-Za-z0-9-_ ]+$/.test(username);
        if (username.length === 0) {
            setError('Il faut écrire votre pseudonyme');
        } else if (!isValid) {
            setError('Le pseudonyme ne peut pas contenir de caractères spéciaux');
        } else {
            setError('');
        }
        return isValid;
    };

    // //generer un nombre random après user
    // function generateRandomUsername() {
    //     const randomNumber = Math.floor(Math.random() * 10000);
    //     return `user#${randomNumber}`;
    // }
    //
    // //on remplis la value de l'input avec le string généré
    // const handleRandomUsername = () => {
    //     inputRandomRef.current.value = generateRandomUsername();
    //     setUser(inputRandomRef.current.value);
    // }

    return (<>
        <div className="overlay"></div>
        <div className="modalFirstCo-container">
            <div className="headerFirstCo-container">
                <h1>Première connexion ? </h1>
                <h3> Insérez votre nom !</h3>
            </div>
            {error && (
                <div className="error-message">
                    <p>{error}</p>
                </div>
            )}
            {successLog && (
                <div className="success-message">
                    <p>{successLog}</p>
                </div>
            )}
            <div className="inputFirstCo-container">
                <input type="text" id="random_user" onChange={(e) => setUser(e.target.value)}
                       required maxLength="15" ref={inputRandomRef}/>
                {/*<button onClick={handleRandomUsername}>Aléatoire</button>*/}
            </div>
            <button className="accept-username" onClick={handleClickUpdateUsername}>Accepter</button>
        </div>
    </>)
};

export default DefineNewUsername;
