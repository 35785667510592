const characterWidth = 46;
const characterHeight = 46;
// Calcul de la position initiale en X du personnage pour le centrer sur le canvas
let initialCharacterX = window.innerWidth / 2 - characterWidth / 2;
// Calcul de la position initiale en Y du personnage pour le centrer sur le canvas avec un décalage de -20
let initialCharacterY = window.innerHeight / 2 - characterHeight / 2 + 90;
export const resetGame = (
  characterRef,
  characterRightImageRef,
  velocityY,
  scoreRef,
  gameOver,
  platformArrayRef,
  platformCountRef,
  brokenPlatformArrayRef,
  initialVelocityRef,
  placeFirstPlatforms,
  updateScore,
  updateBackground,
  setBackButton,
  setRestartButton
) => {
  if (gameOver.current) {
    const initialCharacter = {
      img: characterRightImageRef.current,
      x: initialCharacterX,
      y: initialCharacterY,
      width: characterWidth,
      height: characterHeight,
    };
    initialVelocityRef.current = -7;
    characterRef.current = { ...initialCharacter };
    velocityY.current = initialVelocityRef.current;
    scoreRef.current = 0;
    gameOver.current = false;
    platformArrayRef.current = [];
    brokenPlatformArrayRef.current = [];
    placeFirstPlatforms();
    updateScore();
    updateBackground();
    setBackButton(false);
    setRestartButton(false);
  }
};
