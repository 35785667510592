//detection des plateformes
export const detectPlatform = (character, platform) => {
  const leftOfPlatform = character.x < platform.x + platform.width;
  const rightOfPlatform = character.x + character.width > platform.x;
  const abovePlatform = character.y + character.height >= platform.y;
  const belowPlatform =
    character.y + character.height <= platform.y + platform.height;

  return leftOfPlatform && rightOfPlatform && abovePlatform && belowPlatform;
};

//genération de nouvelles plateformes en continues
export const newPlatform = (
  canvasRef,
  platformArrayRef,
  platformImgRef,
  platformWidth,
  platformHeight
) => {
  const platformHeightMultiplier = 10;
  const verticalSpacing = 10;
  let lastPlatformY =
    platformArrayRef.current.length > 0
      ? platformArrayRef.current[platformArrayRef.current.length - 1].y
      : canvasRef.current.height;
  let newPlatformY =
    lastPlatformY - platformHeightMultiplier - verticalSpacing - 60;

  for (let i = 0; i < platformArrayRef.current.length; i++) {}
  const availableSpace = canvasRef.current.width - platformWidth;
  const randomX =
    Math.floor((Math.random() * availableSpace * 11) / 12) +
    availableSpace / 24;
  let platform = {
    img: platformImgRef.current,
    x: randomX,
    y: newPlatformY,
    width: platformWidth,
    height: platformHeight,
  };

  platformArrayRef.current.push(platform);
};

//meme principes mais pour les plateformes cassées
export const firstBrokenPlatform = (
  canvasRef,
  brokenPlatformArrayRef,
  brokenPlatformImgRef,
  brokenPlatformWidth,
  brokenPlatformHeight,
  scoreRef
) => {
  const newPlatforms = [];
  if (window.innerWidth >= 768) {
    for (let i = 0; i < 5; i++) {
      let randomX = Math.floor(
        Math.random() * (canvasRef.current.width - brokenPlatformWidth)
      );
      let brokenPlatform = {
        img: brokenPlatformImgRef.current,
        x: randomX,
        y: -brokenPlatformHeight - 200 * i - 150,
        width: brokenPlatformWidth,
        height: brokenPlatformHeight,
      };

      brokenPlatformArrayRef.current.push(brokenPlatform);
    }
  } else if (window.innerWidth >= 300) {
    for (let i = 0; i < 4; i++) {
      let randomX = Math.floor(
        Math.random() * (canvasRef.current.width - brokenPlatformWidth)
      );
      let brokenPlatform = {
        img: brokenPlatformImgRef.current,
        x: randomX,
        y: -brokenPlatformHeight - 150 * i - 80,
        width: brokenPlatformWidth,
        height: brokenPlatformHeight,
      };

      brokenPlatformArrayRef.current.push(brokenPlatform);
    }
    brokenPlatformArrayRef.current = [
      ...brokenPlatformArrayRef.current,
      ...newPlatforms,
    ];
  }
};

//génération de plateformes cassées en continue
export const newBrokenPlatform = (
  canvasRef,
  brokenPlatformArrayRef,
  brokenPlatformImgRef,
  brokenPlatformWidth,
  brokenPlatformHeight
) => {
  let randomX = Math.floor(
    Math.random() * (canvasRef.current.width - brokenPlatformWidth)
  );
  let verticalGap = -20;
  let platform = {
    img: brokenPlatformImgRef.current,
    x: randomX,
    y: -brokenPlatformHeight - verticalGap,
    width: brokenPlatformWidth,
    height: brokenPlatformHeight,
  };

  brokenPlatformArrayRef.current.push(platform);
};
