import React, {useEffect, useState} from "react";
import "../styles/modal-ranking.css";
import axiosInstance from "../utils/axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrophy} from "@fortawesome/free-solid-svg-icons";

const ModalRanking = ({onClose}) => {

    const [top10Ranking, setTop10Ranking] = useState([]);
    const [UserScoreRanking, setUserScoreRanking] = useState([]);
    const [position, setPosition] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const id_user = localStorage.getItem("idUser");
    const username = localStorage.getItem("username");

    const upperCaseUsername =
        username.charAt(0).toUpperCase() + username.slice(1);

    //On récupère pour le classement nos users
    const rankingUser = async () => {
        try {
            const response = await axiosInstance.get(
                "/wp-json/keole_wytest/v1/scoregame",
                {
                    params: {
                        id_user: id_user,
                    },
                }
            );

            if (response) {
                const responseData = response.data;
                const userRank = responseData.userScore;
                const positionRank = responseData.position;
                //nsole.log("position dans le classement", positionRank);
                let result = {
                    userRanking: userRank,
                    message: responseData.message,
                    position: positionRank,
                };
                //console.log(result);
                return result;
            }
        } catch (error) {
            console.log("Erreur lors de la connexion :", error);
        }
    };

    // Charger les 10 premiers du classement lors du montage du composant
    useEffect(() => {
        const loadTop10Ranking = async () => {
            try {
                const rankingData = await rankingUser();
                // console.log(rankingData);
                const idUser = rankingData.userRanking;
                const message = rankingData.message;
                const positionId = rankingData.position;
                setTop10Ranking(message);
                setUserScoreRanking(idUser);
                setPosition(positionId !== 0 ? positionId : positionId + 1);
            } catch (error) {
                console.error("Erreur lors du chargement du classement :", error);
            } finally {
                setIsLoading(false);
            }
        };
        loadTop10Ranking();
    }, []);

    return (
        <>
            <div className="overlay" onClick={onClose}></div>
            <div className="modal-container">
                <div className="header-container">
                    <h1>Classement</h1>
                    <span onClick={onClose}>&times;</span>
                </div>
                <div className="content">
                    {isLoading ? (
                        <div className="loader"></div>
                    ) : (
                        <>
                            <div className="modal-content">
                                <ul className="top10">
                                    {top10Ranking.map((entry, index) => (
                                        <li
                                            key={index}
                                            className={
                                                index === 0
                                                    ? "first"
                                                    : index === 1
                                                        ? "second"
                                                        : index === 2
                                                            ? "third"
                                                            : index % 2 === 0
                                                                ? "otherEven"
                                                                : "otherOdd"
                                            }
                                        >
                                            <p className="userPosition">
                                                <span>{index + 1}</span> {entry.username}
                                            </p>
                                            <span className="score">
                        <FontAwesomeIcon
                            icon={faTrophy}
                            className="trophy"
                            style={{color: "gold"}}
                        />
                                                {entry.best_score}
                      </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="userRanking-container">
                                <h2>Votre score</h2>
                                <ul className="userRank">
                                    <li
                                        className={
                                            position === 1
                                                ? "first"
                                                : position === 2
                                                    ? "second"
                                                    : position === 3
                                                        ? "third"
                                                        : position % 2 === 0
                                                            ? "otherEven"
                                                            : "otherOdd"
                                        }
                                    >
                                        <p className="userPosition">
                                            <span>{position} </span> {upperCaseUsername}
                                        </p>
                                        <span className="score">
                      <FontAwesomeIcon icon={faTrophy}/>
                                            {UserScoreRanking}
                    </span>
                                    </li>
                                </ul>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ModalRanking;
