import React from "react";
import {useNavigate, Link} from "react-router-dom";
import {useState, useRef} from "react";
import "../styles/Register-login.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faPhone} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../utils/axios";
import Cookies from "js-cookie";
import {useEffect} from "react";
import CguModal from "./CguModal";

const Register = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [action, setAction] = useState("Inscription");
    const [errorLog, setErrorLog] = useState("");
    const [successLog, setSuccessLog] = useState("");
    const [agreeChecked, setAgreeChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const phoneNumberInput = useRef(null);
    const passwordInput = useRef(null);

    const logo = process.env.PUBLIC_URL + "/assets/images/white.svg";
    const isSignUpFormEmpty = !password || !phoneNumber;

    //insertion des users en bdd
    const createUser = async () => {
        try {
            const response = await axiosInstance.post("/wp-json/keole_wytest/v1/usergame", {
                phone_number: phoneNumber, password: password,
            });
            if (response) {
                let idUser = response.data.id_user;

                let result = {
                    id_user: idUser, message: response.data.message, token: response.data.token,
                };

                switch (response.data.message) {
                    case "PasswordError":
                        setErrorLog("Le mot de passe doit avoir au moins 6 caractères avec au moins une majuscule et un chiffre.");
                        setSuccessLog("");
                        break;
                    case "PhoneError":
                        setErrorLog("Le numéro de téléphone n'est pas valide");
                        setSuccessLog("");
                        break;
                    case "ExistingPhone":
                        setErrorLog("Numéro de téléphone déjà existant");
                        setSuccessLog("");
                        break;
                    case "CreatedAccount":
                        setErrorLog("");
                        setSuccessLog("Votre compte a bien été créé");
                        break;
                    case "MissingInfo":
                        setErrorLog("Informations manquantes");
                        setSuccessLog("");
                        break;
                    default:
                        setErrorLog("Erreur inattendue");
                        setSuccessLog("");
                }
                return result;
            }
        } catch (error) {
            console.log(error);
        }
    };

    //fonction d'inscription si tout les champs sont remplis
    const handleSignUpSubmit = async (e) => {
        e.preventDefault();
        setErrorLog("");
        setSuccessLog("");

        if (action === "Inscription") {
            if (!isSignUpFormEmpty && agreeChecked) {
                try {
                    const result = await createUser();
                    const idUser = result.id_user;
                    const message = result.message;
                    const token = result.token;
                    if (idUser) {
                        localStorage.setItem("idUser", idUser);
                        Cookies.set("authToken", token, {expires: 7});
                        setSuccessLog("Inscription validée");
                        setTimeout(() => {
                            navigate("/menu");
                        }, 2000)
                        setPhoneNumber("");
                        setPassword("");
                        return;
                    }
                    switch (message) {
                        case "PasswordError":
                            setPassword(passwordInput.current.value);
                            passwordInput.current.value = "";
                            break;
                        case "PhoneError":
                        case "ExistingPhone":
                            setPhoneNumber(phoneNumberInput.current.value);
                            phoneNumberInput.current.value = "";
                            break;
                        default:
                            break;
                    }
                } catch (error) {
                    console.error("Erreur lors de l'enregistrement :", error);
                }
            }
            if (!agreeChecked) {
                alert("Veuillez accepter les termes de la politique de confidentialité pour vous inscrire.");
            }
        }
    };

    //redirect au menu si jamais on est connectés
    useEffect(() => {
        const authToken = Cookies.get("authToken");
        if (authToken) {
            navigate("/menu");
        }
    }, [navigate]);

    const handleCheckboxChange = () => {
        setAgreeChecked(!agreeChecked);
    };
    //fonction d'événement pour la modal
    const openModalCgu = () => {
        setShowModal(true);
    };
    const closeModalCgu = () => {
        setShowModal(false);
    };
    //reset du scaling a la connexion pour téléphone
    const resetZoomSubmit = () => {
        document.body.style.transform = "scale(1)";
        window.scrollTo(0, 0);
    };

    return (<div className="login-container">
        <div className="login-content">
            <div className="login-wrapper">
                <img src={logo} id="login-logo" alt="logo"></img>
                <div className="title-container">
                    <div className="title-content">
                        <div className="underline"></div>
                    </div>
                </div>
                {errorLog && (<div className="error-message">
                    <p>{errorLog}</p>
                </div>)}
                {successLog && (<div className="success-message">
                    <p>{successLog}</p>
                </div>)}
                <form className="form-container">
                    <div className="input-container">
                        <label className="visually-hidden">Téléphone:</label>
                        <FontAwesomeIcon icon={faPhone}/>
                        <input
                            type="tel"
                            placeholder="* Numéro de téléphone"
                            id="phone"
                            name="phone_number"
                            maxLength="10"
                            ref={phoneNumberInput}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />
                    </div>

                    <div className="input-container">
                        <label className="visually-hidden">Mot de passe:</label>
                        <FontAwesomeIcon icon={faLock}/>
                        <input
                            type="password"
                            placeholder="* Mot de passe"
                            id="password"
                            name="password"
                            ref={passwordInput}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>

                    <p className="condition">
                        Votre mot de passe doit avoir au moins 6 caractères, dont au moins
                        une majuscule et un chiffre.
                    </p>
                    <div className="cgu">
                        <label
                            className="centered"
                            htmlFor="agreeCheckbox"
                            onClick={openModalCgu}
                        >
                            J'accepte les termes de la politique de confidentialité
                        </label>
                        <input
                            type="checkbox"
                            checked={agreeChecked}
                            onChange={handleCheckboxChange}
                        />

                        {showModal && <CguModal onCloseModal={closeModalCgu}/>}
                    </div>

                    <div className="submit-container">
                        <Link
                            to="/"
                            className={action === "Inscription" ? " gray" : ""}
                            onClick={(e) => {
                                resetZoomSubmit();
                                setAction("Connexion");
                            }}
                        >
                            <span>Déjà inscrit ? </span>
                        </Link>
                        <button
                            type="submit"
                            className={action === "Connexion" ? " gray" : "home-button"}
                            onClick={(e) => {
                                resetZoomSubmit();
                                handleSignUpSubmit(e);
                                setAction("Inscription");
                            }}
                            disabled={action === "Inscription" && isSignUpFormEmpty}
                        >
                            Inscription
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>);
};

export default Register;
