import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: "https://www.wytest.fr/", // Prod
  baseURL: "https://preprod.wytest.fr/", // Preprod
  // baseURL: "http://localhost/wytest/", // Local
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosInstance;
