import React, {useEffect, useState} from "react";
import "../styles/home.css";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPowerOff} from "@fortawesome/free-solid-svg-icons";
import ModalRanking from "./ModalRanking";
import Cookies from "js-cookie";
import DefineNewUsername from "./DefineNewUsername";
import axiosInstance from "../utils/axios";

const Home = () => {

    const logo = process.env.PUBLIC_URL + "/assets/images/white.svg";
    const bird = process.env.PUBLIC_URL + "/assets/images/character-right.png";

    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [isFirstConnection, setIsFirstConnection] = useState(false);
    const [user, setUser] = useState("");

    const idUser = localStorage.getItem("idUser");

    const getUsername = async () => {
        try {
            const response = await axiosInstance.get("/wp-json/keole_wytest/v1/username", {
                params: {
                    id_user: idUser
                },
            });
            if (response) {
                return response.data.username;
            }
        } catch (error) {
            console.log("Erreur lors de la connexion :", error);
        }
    };

    //redirection vers le register si pas connecté
    useEffect(() => {
        const authToken = Cookies.get("authToken");
        if (!authToken) {
            navigate("/");
        }
    }, [navigate]);

    //on veut au demarrage si user = false check si c'est une premiere connexion
    useEffect(() => {
        checkIfFirstConnection()
    }, [])

    //on recupere le username en fonction de l'id si null is firstconnection sinon rien
    const checkIfFirstConnection = async () => {
        const user = await getUsername();
        const isFirstCo = !user;
        setIsFirstConnection(isFirstCo)
        if (!isFirstCo) localStorage.setItem("username", user);

    }
    //fonction d'événement pour la modal
    const openModalRanking = () => {
        setShowModal(true);
    };
    const closeModalRanking = () => {
        setShowModal(false);
    };

    //on clear le localStorage et le token, puis on redirect au logout
    const logout = () => {
        localStorage.clear();
        Cookies.remove("authToken");
        if (localStorage.length === 0) {
            navigate("/");
            return;
        }
    };

    return (<div className="home-container">
        <img src={logo} alt="logo"></img>
        <div className="home-content">
            <Link to="/game" className="redirect-start">
                <button className="home-button">Jouer</button>
            </Link>
            <button className="home-button" onClick={openModalRanking} disabled={isFirstConnection}>
                Classement
            </button>
            {showModal && <ModalRanking onClose={closeModalRanking}/>}
            {isFirstConnection && (
                <DefineNewUsername setIsFirstConnection={setIsFirstConnection}
                                   user={user} setUser={setUser}/>
            )}
            <div className="redirect-login" onClick={logout}>
                <FontAwesomeIcon
                    icon={faPowerOff}
                    className="logout"
                    style={{
                        color: "var(--main)",
                    }}
                />
            </div>
        </div>
        <div className="empty-battery">
            <span>Pour des performances optimales,</span>
            <span>ne pas activer le mode économie d'énergie</span>
        </div>
        <div className="bird-animation">
            <img src={bird} alt="bird"></img>
        </div>
    </div>);
};

export default Home;
