import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import axiosInstance from "../utils/axios";
import Cookies from "js-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRotateLeft, faPhone
} from "@fortawesome/free-solid-svg-icons";

const SendSms = () => {

    const [errorLog, setErrorLog] = useState("");
    const [successLog, setSuccessLog] = useState("");
    const [phone, setPhone] = useState("");

    const phoneInput = useRef(null);

    const navigate = useNavigate();

    const inputEmpty = !phone;
    const logo = process.env.PUBLIC_URL + "/assets/images/white.svg";

    //récupération des users pour la connexion
    const sendPhoneviaUser = async () => {
        try {
            const response = await axiosInstance.get(
                "/wp-json/keole_wytest/v1/resetpassword",
                {
                    params: {
                        phone_number: phone,
                    },
                }
            );

            if (response) {
                const responseData = response.data;
                const idUser = responseData.user;
                let result = {
                    id_user: idUser,
                    message: responseData.message,
                };
                if (responseData.message === "SMS envoyé avec succès") {
                    setErrorLog("");
                    setSuccessLog(responseData.message);
                } else if (responseData.message === "Le numéro de téléphone attribué n'existe pas") {
                    setErrorLog(responseData.message);
                    setSuccessLog("");
                    phoneInput.current.value = "";
                }
                return result;
            }
        } catch (error) {
            console.log("Erreur lors de la connexion :", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorLog("");
        setSuccessLog("");
        if (!inputEmpty) {
            try {
                const result = await sendPhoneviaUser();
                const idUser = result.id_user;
                phoneInput.current.value = "";
                if (idUser) {
                    setPhone("");
                }
            } catch (error) {
                console.error("Erreur lors de la connexion :", error);
            }
        }
    };

    useEffect(() => {
        const authToken = Cookies.get("authToken");
        if (!authToken) {
            navigate("/send-email");
        } else {
            navigate("/menu");
        }
    }, [navigate]);

    return (
        <div className="login-container">
            <div className="login-content">
                <div className="login-wrapper">
                    <img src={logo} id="login-logo" alt="logo"></img>
                    <div className="title-container">
                        <div className="title-content">
                            <div className="underline"></div>
                        </div>
                    </div>
                    {errorLog && (
                        <div className="error-message">
                            <p>{errorLog}</p>
                        </div>
                    )}
                    {successLog && (
                        <div className="success-message">
                            <p>{successLog}</p>
                        </div>
                    )}
                    <form className="form-container">
                        <div className="inputMail-container">
                            <label className="visually-hidden">Téléphone:</label>
                            <FontAwesomeIcon icon={faPhone}/>
                            <input
                                type="tel"
                                placeholder="* Numéro de téléphone"
                                id="phone"
                                name="phone_number"
                                maxLength="15"
                                ref={phoneInput}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                            />
                        </div>
                        <div className="submit-container">
                            <button
                                type="submit"
                                className="home-button"
                                onClick={handleSubmit}
                                disabled={inputEmpty}
                            >
                                Envoyer
                            </button>
                        </div>
                    </form>
                    <span className="return-container">
            <Link to="/" className="return-link">
              <span className="arrow">
                <FontAwesomeIcon icon={faArrowRotateLeft}/>
              </span>
              Retour
            </Link>
          </span>
                </div>
            </div>
        </div>
    );
};

export default SendSms;
