export const drawCanvas = (
  handleUpdateBackground,
  scoreRef,
  contextRef,
  canvasRef,
  gameOver,
  prevScoreRef,
  setBackButton,
  setRestartButton
) => {
  contextRef.current.fillStyle = "white";
  if (!gameOver.current) {
    contextRef.current.font = "bold 18px sans-serif";
    contextRef.current.textAlign = "left";
    contextRef.current.textBaseline = "top";
    contextRef.current.fillText("Score: " + scoreRef.current, 25, 10);
    handleUpdateBackground();
  } else {
    setBackButton(true);
    setRestartButton(true);
    prevScoreRef.current = scoreRef.current;
    contextRef.current.fillStyle = "rgba(0,0,0,0.5)";
    contextRef.current.fillRect(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
  }
};
