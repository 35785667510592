import React from "react";
import {useNavigate, Link} from "react-router-dom";
import {useState, useRef} from "react";
import "../styles/Register-login.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faPhone} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../utils/axios";
import Cookies from "js-cookie";
import {useEffect} from "react";

const Login = () => {

    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [action, setAction] = useState("Connexion");
    const [errorLog, setErrorLog] = useState("");
    const [successLog, setSuccessLog] = useState("");

    const phoneInput = useRef(null);
    const passwordInput = useRef(null);

    const navigate = useNavigate();

    const logo = process.env.PUBLIC_URL + "/assets/images/white.svg";
    const isConnexionFormEmpty = !phone && !password;

    //récupération des users pour la connexion
    const loginUser = async () => {
        try {
            const response = await axiosInstance.get(
                "/wp-json/keole_wytest/v1/usergame",
                {
                    params: {
                        phone: phone,
                        password: password,
                    },
                }
            );

            if (response) {
                const responseData = response.data;
                const idUser = responseData.id_user;
                let result = {
                    id_user: idUser,
                    message: responseData.message,
                    token: responseData.token,
                };
                if (responseData.message === 1) {
                    setErrorLog("");
                    setSuccessLog("Connexion validée");
                } else if (responseData.message === 2) {
                    setErrorLog("Identifiant et/ou mot de passe incorrect");
                    setSuccessLog("");
                }
                return result;
            }
        } catch (error) {
            console.log("Erreur lors de la connexion :", error);
        }
    };

    //fonction de connexion si tout les champs sont remplis
    const handleConnexionSubmit = async (e) => {
        e.preventDefault();
        setErrorLog("");
        setSuccessLog("");

        if (action === "Connexion") {
            if (!isConnexionFormEmpty) {
                try {
                    const result = await loginUser();
                    const idUser = result.id_user;
                    const message = result.message;
                    const token = result.token;
                    // console.log(token);
                    // console.log(idUser);
                    if (idUser) {
                        localStorage.setItem("idUser", idUser);
                        Cookies.set("authToken", token, {expires: 7});
                        setTimeout(() => {
                            navigate("/menu");
                        }, 2000)
                        setPhone("");
                        setPassword("");
                        return;
                    }
                    if (message === 2) {
                        if (phoneInput.current) phoneInput.current.value = "";
                        if (passwordInput.current) passwordInput.current.value = "";
                    }
                } catch (error) {
                    console.error("Erreur lors de la connexion :", error);
                }
            }
        }
    };

    //redirect au menu si jamais on est connectés
    useEffect(() => {
        const authToken = Cookies.get("authToken");
        if (authToken) {
            navigate("/menu");
        }
    }, [navigate]);

    //reset du scaling a la connexion pour téléphone
    const resetZoomSubmit = () => {
        document.body.style.transform = "scale(1)";
        window.scrollTo(0, 0);
    };

    return (
        <div className="login-container">
            <div className="login-content">
                <div className="login-wrapper">
                    <img src={logo} id="login-logo" alt="logo"></img>
                    <div className="title-container">
                        <div className="title-content">
                            <div className="underline"></div>
                        </div>
                    </div>
                    {errorLog && (
                        <div className="error-message">
                            <p>{errorLog}</p>
                        </div>
                    )}
                    {successLog && (
                        <div className="success-message">
                            <p>{successLog}</p>
                        </div>
                    )}
                    <form className="form-container">
                        <div className="input-container">
                            <label className="visually-hidden ">Identifiant:</label>
                            <FontAwesomeIcon icon={faPhone}/>
                            <input
                                type="tel"
                                placeholder="* Identifiant"
                                id="phone"
                                name="phone"
                                maxLength="15"
                                ref={phoneInput}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                            />
                        </div>

                        <div className="input-container">
                            <label className="visually-hidden">Mot de passe:</label>
                            <FontAwesomeIcon icon={faLock}/>
                            <input
                                type="password"
                                placeholder="* Mot de passe"
                                id="password"
                                name="password"
                                ref={passwordInput}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <span>
              <Link to="/send-email" className="link-sendEmail">
                Mot de passe oublié ?
              </Link>
            </span>
                        <div className="submit-container">
                            <Link
                                to="/register"
                                className={action === "Connexion" ? " gray" : "home-button"}
                                onClick={(e) => {
                                    resetZoomSubmit();
                                    setAction("Inscription");
                                }}
                            >
                                Pas encore inscrit ?
                            </Link>
                            <button
                                type="submit"
                                className={action === "Inscription" ? " gray" : "home-button"}
                                onClick={(e) => {
                                    resetZoomSubmit();
                                    handleConnexionSubmit(e);
                                    setAction("Connexion");
                                }}
                                disabled={action === "Connexion" && isConnexionFormEmpty}
                            >
                                Connexion
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
