import React, {useEffect, useState, useRef} from "react";
import {resetGame} from "../constants/reset";
import {updateBackground} from "../constants/updateBackground";
import {updateScore} from "../constants/updateScore";
import {handleMouseMoveCharacter} from "../constants/moveCharacter";
import {drawCanvas} from "../constants/drawCanvas";
import {
    detectPlatform,
    newPlatform,
    firstBrokenPlatform,
    newBrokenPlatform,
} from "../constants/generatePlatform";
import {updateGame} from "../constants/updateGame";
import {blurEvent} from "../constants/blurEvent";
import {Link, useNavigate} from "react-router-dom";
import LoadingPage from "./LoadingPage";
import axiosInstance from "../utils/axios";
import Cookies from "js-cookie";

const Game = () => {
    //Etat local
    const [mouseX, setMouseX] = useState(0);
    const [prevMouseX, setPrevMouseX] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [backButton, setBackButton] = useState(false);
    const [restartButton, setRestartButton] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loader, setLoader] = useState(true);
    const [isScoreInserted, setIsScoreInserted] = useState(false);
    const [bestScore, setBestScore] = useState(null);
    const [animationStarted, setAnimationStarted] = useState(false);
    const [touchScreen, setTouchScreen] = useState(false);
    //Etat par référence
    const initialVelocityRef = useRef(-6.5);
    const platformCountRef = useRef(0);
    const scoreRef = useRef(0);
    const prevScoreRef = useRef(0);
    const gameOver = useRef(false);
    const platformArrayRef = useRef([]);
    const brokenPlatformArrayRef = useRef([]);
    const velocityY = useRef(0);
    const canvasRef = useRef(null);
    const contextRef = useRef(null);
    const switchBackgroundRef = useRef(new Image());
    const characterRightImageRef = useRef(new Image());
    const characterLeftImageRef = useRef(new Image());
    const platformImgRef = useRef(new Image());
    const brokenPlatformImgRef = useRef(new Image());
    //variables
    const bird = process.env.PUBLIC_URL + "/assets/images/character-right.png";
    const touchingFinger =
        process.env.PUBLIC_URL + "/assets/images/whiteHand.svg";
    const characterWidth = 46;
    const characterHeight = 46;
    const gravity = 0.2;
    const initialVelocityY = -7;
    let platformWidth = 80;
    let platformHeight = 20;
    let brokenPlatformWidth = 80;
    let brokenPlatformHeight = 20;
    const idUser = localStorage.getItem("idUser");
    const navigate = useNavigate();

    let initialCharacterX = window.innerWidth / 2 - characterWidth / 2;
    let initialCharacterY = window.innerHeight / 2 - characterHeight / 2 + 90;
    const characterRef = useRef({
        img: null,
        x: initialCharacterX,
        y: initialCharacterY,
        width: characterWidth,
        height: characterHeight,
    });
    const username = localStorage.getItem("username");
    //insertion des scores en bdd
    const insertScore = async () => {
        try {
            const response = await axiosInstance.post(
                "/wp-json/keole_wytest/v1/scoregame",
                {
                    best_score: scoreRef.current,
                    id_user: idUser,
                }
            );
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.log(error);
        }
    };

    //update des scores en bdd
    const updateInsertScore = async () => {
        try {
            const response = await axiosInstance.patch(
                "/wp-json/keole_wytest/v1/scoregame",
                {
                    best_score: scoreRef.current,
                    id_user: idUser,
                }
            );
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.log(error);
        }
    };

    //insertion/update des scores
    useEffect(() => {
        const handleInsertOrUpdateScore = async () => {
            try {
                //on insère, puis stocke si bon message
                const insertedScore = await insertScore();
                if (insertedScore.message === "Score has been inserted") {
                    localStorage.setItem("scoreUser", scoreRef.current);
                    setBestScore(insertedScore.scoreInserted);
                    setLoader(false);
                } else {
                    setBestScore(insertedScore.bestScore);
                    setLoader(false);
                }
                //on update si bon message
                const updatedScore = await updateInsertScore();
                if (updatedScore.message === "Score has been updated") {
                    localStorage.setItem("scoreUser", scoreRef.current);
                    setBestScore(updatedScore.bestScoreUpdated);
                    setLoader(false);
                } else {
                    setBestScore(updatedScore.bestScore);
                    setLoader(false);
                }

                // si il n'y a pas de score, on insère
            } catch (error) {
                console.error(
                    "Erreur lors de l'insertion ou de la mise à jour du score :",
                    error
                );
            }
        };
        if (gameOver.current) {
            handleInsertOrUpdateScore();
        }
    }, [gameOver.current, isScoreInserted]);

    //au click lance l'animation du jeu
    useEffect(() => {
        const handleTouch = () => {
            setAnimationStarted(true);
            setTouchScreen(true);
        };
        const overlayElement = document.querySelector(".overlay-touchscreen");
        if (overlayElement && contextRef.current) {
            overlayElement.addEventListener("click", handleTouch);
        }
        return () => {
            if (overlayElement && contextRef.current) {
                overlayElement.removeEventListener("click", handleTouch);
            }
        };
    }, [contextRef.current, canvasRef.current, touchScreen]);

    //appeler l'update lorsque anim passe a true
    useEffect(() => {
        if (animationStarted && touchScreen) {
            update();
        }
    }, [animationStarted, touchScreen]);

    //redirect sur login si jamais pas connecté
    useEffect(() => {
        const authToken = Cookies.get("authToken");
        if (!authToken) {
            navigate("/");
        } else if (!username) {
            navigate("/menu");
        }
    }, [navigate]);

    //update les différentes tailles d'écrans
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };
        const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
            if (!isLoading) {
                initializeGame();
            }
        }, 1000);
        window.addEventListener("resize", handleResize);

        return () => {
            clearTimeout(loadingTimeout);
            window.removeEventListener("resize", handleResize);
        };
    }, [width, height, isLoading]);

    //update les broken platform
    useEffect(() => {
        if (
            scoreRef.current >= 2000 &&
            brokenPlatformArrayRef.current.length === 0 &&
            !gameOver.current
        ) {
            generatefirstBrokenPlatform();
        }
        platformCountRef.current =
            window.innerHeight >= 900
                ? 12
                : window.innerHeight >= 700
                    ? 10
                    : window.innerHeight >= 600
                        ? 9
                        : 7;
    }, [scoreRef.current]);

    //chargement d'image en asynchrone
    const loadImage = (image) => {
        return new Promise((resolve, reject) => {
            image.onload = () => resolve(image);
            image.onerror = (error) => reject(error);
        });
    };

    //initialisation d'images en asynchrones
    const initializeImages = async () => {
        await Promise.all([
            loadImage(characterRightImageRef.current),
            loadImage(characterLeftImageRef.current),
            loadImage(platformImgRef.current),
            loadImage(brokenPlatformImgRef.current),
        ]);

        characterRef.current.img = characterRightImageRef.current;
    };

    //gérer la visibilité
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    //initialisation du jeu
    const initializeGame = () => {
        toggleVisibility();
        const canvas = canvasRef.current;
        canvas.width = width !== 0 ? width : window.innerWidth;
        canvas.height = height !== 0 ? height : window.innerHeight;
        if (canvas) {
            const context = canvas.getContext("2d");
            contextRef.current = context;
        }
        characterRightImageRef.current.src =
            process.env.PUBLIC_URL + "/assets/images/character-right.png";

        characterLeftImageRef.current.src =
            process.env.PUBLIC_URL + "/assets/images/character-left.png";

        platformImgRef.current.src =
            process.env.PUBLIC_URL + "/assets/images/platform.png";

        brokenPlatformImgRef.current.src =
            process.env.PUBLIC_URL + "assets/images/platform-broken.png";

        initializeImages().then(() => {
            velocityY.current = initialVelocityRef.current;
            requestAnimationFrame(update);
            placeFirstPlatforms();
        });
    };

    //update des différentes données du canvas
    const update = () => {
        updateGame(
            canvasRef,
            contextRef,
            velocityY,
            gravity,
            characterRef,
            platformArrayRef,
            scoreRef,
            initialVelocityY,
            gameOver,
            detectPlatform,
            generateNewPlatform,
            generateNewBrokenPlatform,
            designCanvas,
            brokenPlatformArrayRef,
            handleUpdateScore,
            handleBlurEvent,
            animationStarted,
            platformCountRef,
            initialVelocityRef
        );
    };

    //dessin des differentes composantes du canvas
    const designCanvas = () => {
        if (characterRef.current.img) {
            contextRef.current.drawImage(
                characterRef.current.img,
                characterRef.current.x,
                characterRef.current.y,
                characterRef.current.width,
                characterRef.current.height
            );
        }
        drawCanvas(
            handleUpdateBackground,
            scoreRef,
            contextRef,
            canvasRef,
            gameOver,
            prevScoreRef,
            setBackButton,
            setRestartButton
        );
    };

    //placements des premieres plateformes
    const placeFirstPlatforms = () => {
        const newPlatforms = [];
        for (let i = 0; i < platformCountRef.current; i++) {
            const platformHeightMultiplier = 90;
            const verticalSpacing = 20;
            const availableSpace = canvasRef.current.width - platformWidth;
            const randomX =
                Math.floor((Math.random() * availableSpace * 11) / 12) +
                availableSpace / 24;
            let platformY =
                canvasRef.current.height -
                platformHeightMultiplier * i -
                verticalSpacing - 150;
            let platform = {
                img: platformImgRef.current,
                x: randomX,
                y: platformY,
                width: platformWidth,
                height: platformHeight,
            };
            newPlatforms.push(platform);
        }

        platformArrayRef.current = [...platformArrayRef.current, ...newPlatforms];
    };

    //génération de nouvelles plateformes
    const generateNewPlatform = () => {
        newPlatform(
            canvasRef,
            platformArrayRef,
            platformImgRef,
            platformWidth,
            platformHeight
        );
    };

    //placements des premieres plateformes cassées
    const generatefirstBrokenPlatform = () => {
        firstBrokenPlatform(
            canvasRef,
            brokenPlatformArrayRef,
            brokenPlatformImgRef,
            brokenPlatformWidth,
            brokenPlatformHeight,
            scoreRef
        );
    };

    //génération des nouvelles plateformes cassées
    const generateNewBrokenPlatform = () => {
        newBrokenPlatform(
            canvasRef,
            brokenPlatformArrayRef,
            brokenPlatformImgRef,
            brokenPlatformWidth,
            brokenPlatformHeight
        );
    };

    //déplacement du personnage au survol de la souris/tactile téléphone
    const onMouseMoveCharacter = (e) => {
        handleMouseMoveCharacter(
            e,
            canvasRef,
            setMouseX,
            characterRef,
            prevMouseX,
            setPrevMouseX,
            characterRightImageRef,
            characterLeftImageRef
        );
    };

    //reset de la partie
    const reset = () => {
        resetGame(
            characterRef,
            characterRightImageRef,
            velocityY,
            scoreRef,
            gameOver,
            platformArrayRef,
            platformCountRef,
            brokenPlatformArrayRef,
            initialVelocityRef,
            placeFirstPlatforms,
            handleUpdateScore,
            handleUpdateBackground,
            setBackButton,
            setRestartButton,
            setAnimationStarted,
            setTouchScreen
        );
    };

    //update de score
    const handleUpdateScore = () => {
        updateScore(velocityY, scoreRef);
    };

    //Switch à partir du score
    const handleUpdateBackground = () => {
        updateBackground(scoreRef, switchBackgroundRef, canvasRef, contextRef);
    };
    //événements de blur à partir de 6000 de score
    const handleBlurEvent = () => {
        blurEvent(canvasRef, scoreRef, gameOver);
    };

    return (
        <div className="block-container">
            <div className="board-container">
                {isLoading && <LoadingPage/>}
                {gameOver.current && backButton && restartButton && (
                    <>
                        <div className="menu-container">
                            <div className="score-content">
                                {loader ? (
                                    <div className="loader-gameboy"></div>
                                ) : (
                                    <>
                                        <h2>GAME OVER</h2>
                                        <p>Score: {scoreRef.current}</p>
                                        <p>Best score: {bestScore}</p>
                                        <div className="birdGameOver">
                                            <img src={bird} alt="bird"></img>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="button-container">
                                <button className="reset-button" onClick={reset}>
                                    Rejouer
                                </button>
                                <Link to="/menu" className="link-menu">
                                    Menu
                                </Link>
                            </div>
                        </div>
                    </>
                )}
                {!isLoading && !touchScreen && (
                    <div className="overlay-touchscreen">
                        <img src={touchingFinger}></img>
                    </div>
                )}
                <canvas
                    ref={canvasRef}
                    //onMouseMove={onMouseMoveCharacter}
                    onTouchMove={onMouseMoveCharacter}
                    style={{width: "100%", height: "100%"}}
                ></canvas>
            </div>
        </div>
    );
};

export default Game;
