import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../utils/axios";
import Cookies from "js-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";

const ResetPassword = () => {

    const [errorLog, setErrorLog] = useState("");
    const [successLog, setSuccessLog] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showRedirect, setShowRedirect] = useState(false);
    const [countdown, setCountdown] = useState(5);

    const passwordInput = useRef(null);
    const confirmPasswordInput = useRef(null);

    const navigate = useNavigate();

    const inputEmpty = !password || !confirmPassword;
    const logo = process.env.PUBLIC_URL + "/assets/images/white.svg";

    //token via url
    const {token} = useParams();

    const UpdatePassword = async () => {
        try {
            const response = await axiosInstance.patch(
                "/wp-json/keole_wytest/v1/resetpassword",
                {
                    password: password,
                    confirmPassword: confirmPassword,
                    token: token,
                }
            );
            if (response) {
                let results = null;
                switch (response.data.message) {
                    case "SamePassword":
                        setErrorLog("Mot de passe déjà existant");
                        setSuccessLog("");
                        return response.data.message;
                    case "NotSamePassword":
                        setErrorLog("Mot de passes invalides, veuillez réessayer");
                        setSuccessLog("");
                        return response.data.message;
                    case "ConditionsNotSatisfied":
                        setErrorLog(
                            "Le mot de passe doit avoir au moins 6 caractères avec au moins une majuscule et un chiffre."
                        );
                        setSuccessLog("");
                        return response.data.message;
                    default:
                        results = {
                            userId: response.data.userId,
                            updatedPassword: response.data.password,
                            isUpdated: response.data.updated,
                        };
                }

                return results;
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (showRedirect) {
            const countdownInterval = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);

            setTimeout(() => {
                clearInterval(countdownInterval);
                navigate("/");
            }, countdown * 1000);

            return () => clearInterval(countdownInterval);
        }
    }, [showRedirect, countdown, navigate]);

    useEffect(() => {
        const authToken = Cookies.get("authToken");
        if (!authToken) {
            navigate("/reset-password/" + token);
        } else {
            navigate("/menu");
        }
        //console.log("Token récupéré :", token);
    }, [token, navigate]); // Effectue l'effet lorsque le token ou la fonction de navigation change

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorLog("");
        setSuccessLog("");

        const passwordUpdated = await UpdatePassword();
        const tokenById = passwordUpdated.userId;
        const updatedPassword = passwordUpdated.updatedPassword;
        const isUpdated = passwordUpdated.isUpdated;
        if (tokenById && isUpdated) {
            setSuccessLog("Mot de passe réinitialisé");
            setTimeout(() => {
                setSuccessLog("");
                setShowRedirect(true);
            }, 2000);

            // console.log(tokenById);
            // console.log(updatedPassword);
            // console.log(isUpdated);
        }
    };

    return (
        <div className="login-container">
            <div className="login-content">
                <div className="login-wrapper">
                    <img src={logo} id="login-logo" alt="logo"></img>
                    <div className="title-container">
                        <div className="title-content">
                            <div className="underline"></div>
                        </div>
                    </div>
                    {errorLog && (
                        <div className="error-message">
                            <p>{errorLog}</p>
                        </div>
                    )}
                    {successLog && (
                        <div className="success-message">
                            <p>{successLog}</p>
                        </div>
                    )}
                    {showRedirect && (
                        <div className="redirect-message">
                            <p>
                                Vous allez être redirigé vers la page de connexion dans
                                <span> {countdown}</span> secondes.
                            </p>
                        </div>
                    )}
                    {!showRedirect && (
                        <form className="form-container">
                            <div className="inputMail-container">
                                <label className="visually-hidden">Nouveau mot de passe:</label>
                                <FontAwesomeIcon icon={faLock}/>
                                <input
                                    type="password"
                                    placeholder="* Nouveau mot de passe"
                                    id="password"
                                    ref={passwordInput}
                                    name="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                ></input>
                            </div>
                            <div className="inputMail-container">
                                <label className="visually-hidden">
                                    Confirmer le mot de passe:
                                </label>
                                <FontAwesomeIcon icon={faLock}/>
                                <input
                                    type="password"
                                    placeholder="* Confirmer le mot de passe"
                                    id="password"
                                    ref={confirmPasswordInput}
                                    name="confirmPassword"
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                ></input>
                            </div>
                            <p className="condition">
                                Votre mot de passe doit avoir au moins 6 caractères, dont au
                                moins une majuscule et un chiffre.
                            </p>
                            <div className="submit-container">
                                <button
                                    type="submit"
                                    className="home-button"
                                    onClick={handleSubmit}
                                    disabled={inputEmpty}
                                >
                                    Envoyer
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
