import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from "react-router-dom";
import Login from "./Login";
import Game from "./Game";
import Home from "./Home";
import SendEmail from "./SendSmsForm";
import "../styles/App.css";
import ResetPassword from "./ResetPasswordForm";
import Register from "./Register";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/send-email" element={<SendEmail/>}/>
                <Route path="/reset-password/:token" element={<ResetPassword/>}/>
                <Route path="/menu" element={<Home/>}/>
                <Route path="/game" element={<Game/>}/>
            </Routes>
        </Router>
    );
}

export default App;
