export const handleMouseMoveCharacter = (
  e,
  canvasRef,
  setMouseX,
  characterRef,
  prevMouseX,
  setPrevMouseX,
  characterRightImageRef,
  characterLeftImageRef
) => {
  const clientX =
    e.touches && e.touches.length > 0 ? e.touches[0].clientX : e.clientX;

  let rect = canvasRef.current.getBoundingClientRect();
  const newMouseX = clientX - rect.left;

  setMouseX(newMouseX);

  // Modification directe de characterRef.current au lieu de setCharacter
  characterRef.current.x = newMouseX - characterRef.current.width / 2;

  // Limite gauche du canvas
  if (characterRef.current.x < 0) {
    characterRef.current.x = 0;
  }

  // Limite droite du canvas
  if (
    characterRef.current.x + characterRef.current.width >
    canvasRef.current.width
  ) {
    characterRef.current.x =
      canvasRef.current.width - characterRef.current.width;
  }

  // Mise à jour de l'image en fonction du mouvement
  if (newMouseX > prevMouseX) {
    //Déplacement vers la droite
    characterRef.current.img = characterRightImageRef.current;
  } else if (newMouseX < prevMouseX) {
    //Déplacement vers la gauche
    characterRef.current.img = characterLeftImageRef.current;
  }
  // Met à jour la position précédente de la souris de manière directe
  setPrevMouseX(newMouseX);
};
