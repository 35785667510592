import React from "react";
import "../styles/loading-page.css";

const LoadingPage = () => {
  return (
    <div className="loading-container">
      <div className="overlay-loading">
        <p className="loading-dots">
          chargement en cours
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </p>
        <div className="loading-bar"></div>
      </div>
    </div>
  );
};

export default LoadingPage;
