export const updateGame = (
  canvasRef,
  contextRef,
  velocityY,
  gravity,
  characterRef,
  platformArrayRef,
  scoreRef,
  initialVelocityY,
  gameOver,
  detectPlatform,
  generateNewPlatform,
  generateNewBrokenPlatform,
  designCanvas,
  brokenPlatformArrayRef,
  handleUpdateScore,
  handleBlurEvent,
  animationStarted,
  platformCountRef,
  initialVelocityRef
) => {
  const fps = 120;
  if (animationStarted) {
    setTimeout(() => {
      requestAnimationFrame(() => {
        updateGame(
          canvasRef,
          contextRef,
          velocityY,
          gravity,
          characterRef,
          platformArrayRef,
          scoreRef,
          initialVelocityY,
          gameOver,
          detectPlatform,
          generateNewPlatform,
          generateNewBrokenPlatform,
          designCanvas,
          brokenPlatformArrayRef,
          handleUpdateScore,
          handleBlurEvent,
          animationStarted,
          platformCountRef,
          initialVelocityRef
        );
      });
    }, 1500 / fps);
  }

  //on stoppe l'update au game over
  if (gameOver.current) {
    return;
  }

  //on clear le rectangle a chaque update
  contextRef.current.clearRect(
    0,
    0,
    canvasRef.current.width,
    canvasRef.current.height
  );

  //initialisation du jump
  velocityY.current += gravity;
  //console.log("vitesse:", velocityY.current);
  characterRef.current.y += velocityY.current;

  //si il dépasse du canvas alors game over
  if (characterRef.current.y > canvasRef.current.height) {
    gameOver.current = true;
  }

  initialVelocityRef.current =
    scoreRef.current >= 20000
      ? -5
      : scoreRef.current >= 15000
      ? -5.5
      : scoreRef.current >= 10000
      ? -6
      : scoreRef.current >= 5000
      ? -6.5
      : -7;

  const dificulties = [
    { score: 20000, velocity: -21 },
    { score: 15000, velocity: -18 },
    { score: 10000, velocity: -15 },
    { score: 5000, velocity: -11 },
  ];
  let velocity = -8;
  for (let i = 0; i < platformArrayRef.current.length; i++) {
    let platform = platformArrayRef.current[i];
    if (
      velocityY.current < 0 &&
      characterRef.current.y < canvasRef.current.height
    ) {
      for (const dificulty of dificulties) {
        if (scoreRef.current >= dificulty.score) {
          velocity = dificulty.velocity;
          break;
        }
      }
      platform.y -= velocity;
    }
    if (
      detectPlatform(characterRef.current, platform) &&
      velocityY.current >= 2.25
    ) {
      velocityY.current = initialVelocityRef.current;
      if (
        characterRef.current.y > (canvasRef.current.height * 2) / 3 &&
        scoreRef.current >= 20000
      ) {
        velocityY.current = velocity - 1;
      } else if (
        characterRef.current.y > (canvasRef.current.height * 2) / 3 &&
        scoreRef.current >= 10000
      ) {
        velocityY.current = velocity;
      }
    }
    contextRef.current.drawImage(
      platform.img,
      platform.x,
      platform.y,
      platform.width,
      platform.height
    );
  }

  if (characterRef.current.y < (canvasRef.current.height * 1) / 6) {
    characterRef.current.y = (canvasRef.current.height * 1) / 6;
  }

  //génération de plateformes cassées
  for (let i = 0; i < brokenPlatformArrayRef.current.length; i++) {
    let brokenPlatform = brokenPlatformArrayRef.current[i];
    if (
      velocityY.current < 0 &&
      characterRef.current.y < canvasRef.current.height
    ) {
      for (const dificulty of dificulties) {
        if (scoreRef.current >= dificulty.score) {
          velocity = dificulty.velocity;
          break;
        }
      }
      brokenPlatform.y -= velocity;
    }
    if (
      detectPlatform(characterRef.current, brokenPlatform) &&
      velocityY.current >= 2.25
    ) {
      if (scoreRef.current >= 15000) {
        velocityY.current = -velocity + 11;
      } else if (scoreRef.current >= 10000) {
        velocityY.current = -velocity + 9;
      } else if (scoreRef.current >= 5000) {
        velocityY.current = -velocity + 7;
      } else {
        velocityY.current = -velocity + 5;
      }
      brokenPlatform.y += velocityY.current;
    }

    contextRef.current.drawImage(
      brokenPlatform.img,
      brokenPlatform.x,
      brokenPlatform.y,
      brokenPlatform.width,
      brokenPlatform.height
    );
  }
  //Permet de generer un nouvelle platforme des que la premiere depasse du cavnas
  while (
    platformArrayRef.current.length > 0 &&
    platformArrayRef.current[0].y > canvasRef.current.height
  ) {
    platformArrayRef.current.shift();
    generateNewPlatform();
  }

  //meme principe mais pour les plateformes cassés
  while (
    brokenPlatformArrayRef.current.length > 0 &&
    brokenPlatformArrayRef.current[0].y > canvasRef.current.height
  ) {
    brokenPlatformArrayRef.current.shift();
  }
  //on ne l'inclus pas dans la boucle car on veut que la plateformes fall dawn
  if (scoreRef.current >= 2000 && brokenPlatformArrayRef.current === 0) {
    generateNewBrokenPlatform();
  }
  //appels des fonctions à update
  designCanvas();
  handleUpdateScore();
  handleBlurEvent();

  gravity = scoreRef.current >= 20000 ? 0.3 : 0.25;
  //console.log(gravity);
};
