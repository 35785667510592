//evenements de flou a partir d'un certain score
export const blurEvent = (canvasRef, scoreRef, gameOver) => {
  if (!gameOver.current) {
    if (scoreRef.current >= 15000 && scoreRef.current <= 18000) {
      canvasRef.current.style.filter = "blur(7px)";
    } else if (scoreRef.current >= 6000 && scoreRef.current <= 9000) {
      canvasRef.current.style.filter = "blur(5px)";
    } else {
      canvasRef.current.style.filter = "none";
    }
  } else {
    canvasRef.current.style.filter = "none";
  }
};
