//switch de background à partir d'un certain score
export const updateBackground = (
  scoreRef,
  switchBackgroundRef,
  canvasRef,
) => {
  let backgroundSrc;
  let transitionClass;
  if (scoreRef.current >= 10000) {
    backgroundSrc =
      process.env.PUBLIC_URL + "assets/images/thirdBackground.png";
    canvasRef.current.classList.remove("transition-second");
    transitionClass = "transition-third";
  } else if (scoreRef.current >= 5000) {
    backgroundSrc =
      process.env.PUBLIC_URL + "assets/images/secondBackground.png";
    canvasRef.current.classList.remove("undefined");
    transitionClass = "transition-second";
  } else {
    backgroundSrc =
      process.env.PUBLIC_URL + "assets/images/firstBackground.png";
  }

  switchBackgroundRef.current.src = backgroundSrc;
  switchBackgroundRef.current.onload = function () {
    canvasRef.current.classList.add(transitionClass);
    canvasRef.current.style.backgroundImage = 'url("' + backgroundSrc + '")';
  };
};
