import React from "react";
import "../styles/cgu-modal.css";

const CguModal = ({onCloseModal}) => {
  
  return(
  <>
    <div className="overlay" onClick={onCloseModal}></div>
    <div className="modalcgu-container">
      <div className="modalcgu-content">
        <div className="header-container">
          <div className="empty-div"></div>
          <span className="modalForm-close" onClick={onCloseModal}>
            &#10006;
          </span>
        </div>
        <h2>
            CONDITIONS G&Eacute;N&Eacute;RALES D&apos;UTILISATION ET DE
            PROTECTION DES DONN&Eacute;ES PERSONNELLES
          </h2>
        <div className="modalForm-text">
          <h6>1. Préambule</h6>
          <p>
            Dans le cadre de son activité de développement et de
            commercialisation d&apos;une solution de dépistage auditif dont
            l&apos;objet principal est de mettre en relation les patients ayant
            un déficit auditif avec un audioprothésiste partenaire WyDev est
            amené à traiter des informations personnelles vous concernant.
          </p>
          <p>
            Un nouveau Règlement Général sur la Protection de Données n°2016/679
            relatif à la protection des personnes physiques à l&apos;égard du
            traitement des données à caractère personnelles et à la libre
            circulation de ces données (dit « RGPD ») est entré le 25 mai 2018.
            Ce Règlement fait évoluer, de manière importante, la Loi
            Informatique et Libertés n°78-17 du 6 janvier 1978 applicable,
            jusqu&apos;alors en France.
          </p>
          <p>
            WyDev soucieuse de la confidentialité de vos données personnelles
            renforce sa politique en matière protection des données personnelles
            afin de se conformer aux obligations par la Règlementation en
            vigueur et en particulier, aux obligations prévues par le RGPD. Les
            présentes « Conditions Générales d&apos;utilisation et de Protection
            des Données personnelles » vous informent de la manière dont nous
            recueillons et traitons vos données personnelles.
          </p>
          <p>
            Nous n&apos;utilisons vos données personnelles que dans les cas
            prévus par la loi :
          </p>
          <ul>
            <li>L&apos;exécution d&apos;un contrat conclu avec vous</li>
            <li>Le respect d&apos;une obligation légale</li>
            <li>
              Votre consentement à l&apos;utilisation de ces données
              personnelles
            </li>
            <li>
              L&apos;existence d&apos;un intérêt légitime à utiliser ces données
              personnelles. L&apos;intérêt légitime est un ensemble de raisons
              commerciales ou d&apos;affaires qui justifie l&apos;utilisation de
              vos données personnelles par WyDev.
            </li>
          </ul>
          <h6>
            2. Données personnelles que nous sommes susceptibles de recueillir
            et de traiter sur vous
          </h6>
          <p>
            Vos données personnelles sont collectées lorsque que vous remplissez
            le formulaire en ligne à la fin de votre test de dépistage auditif.{" "}
          </p>
          <p>Ces données personnelles peuvent par exemple, concerner :</p>
          <ul>
            <li>
              Des informations relatives à votre identité, personnes physiques
              telles que votre nom, prénom, ainsi que votre tranche d&apos;âge.
            </li>
            <li>
              Vos coordonnées postales, adresses de messageries électroniques,
              numéros de téléphones.
            </li>
            <li>
              Les informations que vous nous communiquez en remplissant des
              formulaires ou en nous les transmettant, que ce soit par
              téléphone, par courriers électroniques ou par tout autre moyen de
              communication en ligne.
            </li>
          </ul>
          <h6>3. Comment utilisons-nous vos Données personnelles ?</h6>
          <p>
            Nous n&apos;utilisons vos données personnelles que si vous y
            consentez ou que si cette utilisation repose sur l&apos;un des
            fondements juridiques prévus par la loi à savoir :
          </p>
          <p>
            Ces données personnelles sont collectées et traitées pour diverses
            raisons, et en particulier pour :
          </p>
          <ul>
            <li>Répondre à toute demande que vous nous adresse</li>
            <li>
              Répondre à l&apos;ensemble de nos obligations légales,
              réglementaires ou fiscales, en nous conformant à toute loi et
              règlement applicable, en les partageants si nécessaire, avec un
              régulateur ou une autorité compétente, telle que la CNIL, dans le
              strict respect de la loi. La finalité de cette transmission est de
              démontrer aux autorités compétentes que nous respectons le RGPD et
              nos obligations légales.
            </li>
          </ul>
          <h6>4. Partage de vos Données à caractère personnel</h6>
          <p>
            Nous sommes susceptibles de transférer et de divulguer vos données à
            caractère personnel à :
          </p>
          <ul>
            <li>Nos partenaires professionnels de santé</li>
            <li>Toute autorité de contrôle, telles que la CNIL</li>
            <li>
              Toute personne concernée en cas de litige (avocats, huissiers de
              justice etc.)
            </li>
            <li>
              À la demande de toutes autorités judiciaires ou administratives.
            </li>
          </ul>
          <h6>5. Partage de vos Données à caractère personnel</h6>
          <p>
            Nous sommes susceptibles de transférer et de divulguer vos données à
            caractère personnel à :
          </p>
          <ul>
            <li>Nos partenaires professionnels de santé</li>
            <li>Toute autorité de contrôle, telles que la CNIL</li>
            <li>
              Toute personne concernée en cas de litige (avocats, huissiers de
              justice etc.)
            </li>
            <li>
              À la demande de toutes autorités judiciaires ou administratives.
            </li>
          </ul>
          <h6>6. Conservation de vos données</h6>
          <p>
            Vous disposez de droits sur les données personnelles vous concernant
            :
          </p>
          <ul>
            <li>
              Le droit d&apos;obtenir des informations sur les données
              personnelles que nous détenons sur vous et les traitements mis en
              œuvre
            </li>
            <li>
              Le droit de modifier ou de corriger vos données personnelles
            </li>
            <li>
              Le droit de demander la suppression de vos données personnelles
              (droit à l&apos;oubli ou d&apos;effacement) étant ici rappelé que
              des dispositions légales ou réglementaires peuvent nous imposer de
              les conserver.
            </li>
            <li>
              Le droit de nous demander de restreindre ou de vous opposer au
              traitement de vos données personnelles, dans certaines
              circonstances.
            </li>
          </ul>
          <p>
            Vous pouvez exercer vos droits en nous adressant un mail
            l&apos;adresse suivante : contact.wytest@wydev.fr
          </p>
        </div>
      </div>
    </div>
  </>
  );
};

export default CguModal;
